import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Inventory from '@/models/Inventory'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'admanager_id',
    label: 'GAM ID',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle',
  },
  {
    key: 'device',
    label: 'Devices',
    sortable: false,
    show: false,
    tdClass: 'align-middle',
    formatter: (_: string, __: string, inventory: Inventory) => (inventory.devices.length > 0
      ? inventory.devices.join(', ')
      : 'N/A'),
  },
  {
    key: 'live',
    label: 'Live',
    sortable: true,
    show: false,
    type: 'boolean',
    tdClass: 'align-middle',
  },
  {
    key: 'length',
    label: 'AdUnit Length',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
    formatter: (_: string, __: string, inventory: Inventory) => (length && length >= 15 ? `${length}s` : 'Any'),
  },
  {
    key: 'group',
    label: 'Group',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
    formatter: (value: string) => {
      const group = Inventory.module.type_options.find(
        o => o.value === value,
      )
      return group ? group.name : value
    },
  },
  {
    key: 'publisher',
    label: 'Publisher',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
    formatter: (value: string) => value ?? '-',
  },
  {
    key: 'schedulers',
    label: 'Schedulers',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    formatter: (_: any, __: any, inventory: Inventory) => (inventory.disable_schedulers
      ? 'Disabled'
      : inventory.allSchedulers.length === 0
        ? 'No'
        : `${inventory.active_schedulers.length}/${inventory.allSchedulers.length}`),
    color: (inventory: Inventory) => (inventory.allSchedulers.length === 0
      ? 'danger'
      : inventory.allSchedulers.length > inventory.active_schedulers.length
        ? 'warning'
        : 'info'),
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    tdClass: 'align-middle text-center',
    color: (inventory: Inventory) => (inventory.status ? 'success' : 'danger'),
    formatter: (_: string, __: string, inventory: Inventory) => (inventory.status ? 'active' : 'inactive'),
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    show: true,
    type: 'action',
    tdClass: 'align-middle text-center',
    actions: [
      {
        icon: 'search',
        title: 'Show Details',
        event: 'details',
      },
      {
        icon: 'sitemap',
        title: 'View child Ad Units',
        event: 'viewChildren',
      },
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        show: (i: Inventory) => user && user.can('inventory', 'edit'),
      },
      {
        icon: 'pause',
        title: 'Deactivate',
        event: 'toggleStatus',
        show: (i: Inventory) => i.status && user && user.can('inventory', 'edit'),
      },
      {
        icon: 'play',
        title: 'Activate',
        event: 'toggleStatus',
        show: (i: Inventory) => !i.status && user && user.can('inventory', 'edit'),
      },
      {
        customIcon: 'stopwatch-slash',
        title: 'Disable Schedulers',
        event: 'toggleSchedulers',
        show: (i: Inventory) => !i.disable_schedulers && user && user.can('inventory', 'edit'),
      },
      {
        customIcon: 'stopwatch',
        title: 'Enable Schedulers',
        event: 'toggleSchedulers',
        show: (i: Inventory) => i.disable_schedulers && user && user.can('inventory', 'edit'),
      },
      {
        icon: 'history',
        title: 'View Action History',
        event: 'viewHistory',
      },
    ],
  },
]
