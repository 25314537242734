








































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import Inventory from '@/models/Inventory'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Creative from '@/models/Creative'
import SelectOption from '@/models/interface/SelectOption'

@Component({
  components: {
    CompanyPicker,
    SelectPicker,
    FormInput,
  },
})
export default class InventoryForm extends ViewModel {
  @Prop()
  public id!: string;

  public view_mode!: string;

  public show_sidebar!: boolean;

  public modal: any = {
    empty: false,
  };

  public inventory: Inventory = new Inventory();

  public loading: boolean = false;

  public busy = true;

  public devices: string[] = [];

  public status_options = [
    {
      value: true,
      name: 'Active',
    },
    {
      value: false,
      name: 'Inactive',
    },
  ];

  public bool_options = [
    {
      value: false,
      name: 'Yes',
    },
    {
      value: true,
      name: 'No',
    },
  ];

  public get device_options() {
    return Inventory.module.device_options
  }

  public get inventory_options() {
    return Inventory.module.type_options
  }

  public get publisher_options() {
    return Inventory.module.publisher_options
  }

  public get live_options() {
    return Inventory.module.live_options
  }

  public get creative_type_options() {
    const options = Creative.module.type_options.map(a => ({ ...a }))
    options.unshift(new SelectOption('Any', null))
    return options
  }

  @Watch('devices')
  public updateDevices() {
    this.inventory.ctv = this.devices.includes('ctv')
    this.inventory.mobile = this.devices.includes('mobile')
    this.inventory.desktop = this.devices.includes('desktop')
  }

  public update() {
    this.loading = true
    this.inventory
      .save()
      .then((response: any) => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'Inventories' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public onSubmit() {
    this.loading = true

    this.update()
  }

  public cancel() {
    this.$router.push({ name: 'Inventories' })
  }

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  public init() {
    if (this.id) {
      this.busy = true
      Inventory.get(this.id).then(o => {
        if (o instanceof Inventory) {
          this.inventory = o
          this.devices = this.inventory.devices
        }
        this.busy = false
      })
    } else {
      this.inventory = new Inventory()
      this.busy = false
    }
  }

  public mounted() {
    this.init()
  }
}
