

























































































































































































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import IconAction from '@/components/IconAction/IconAction.vue'
import Inventory from '@/models/Inventory'
import Widget from '@/components/Widget/Widget.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import AdunitScheduler from '@/models/AdunitScheduler'
import { clone as _clone } from 'lodash'
import LiveEvent from '@/models/LiveEvent'
import fields from './fields'
import ViewModel from '../../models/ViewModel'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    FullCalendar,
  },
})
export default class InventoryHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement;

  @Ref() readonly searchInput!: SearchInput;

  public target: Inventory = new Inventory();

  public target_scheduler: AdunitScheduler = new AdunitScheduler();

  public modal: any = {
    status: false,
    schedulers: false,
    enable_scheduler: false,
    disable_scheduler: false,
    history: false,
  };

  public query: string[] = [
    'is_not:archived',
    // "is:top_level",
    // 'has:schedulers',
  ];

  public page_size: number = 25;

  public sort_by: string = 'name';

  public sort_desc: boolean = false;

  public page: number = 1;

  public records: number = 0;

  public loading: boolean = false;

  public busy: boolean = false;

  public fieldFilters: any = {};

  public fields: Array<any> = [];

  public history: Array<any> = [];

  public history_records: number = 0;

  public history_loading: boolean = false;

  public calendarOptions(inventory: Inventory) {
    return {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, rrulePlugin],
      initialView: 'listMonth',
      nowIndicator: false,
      events: inventory.calendarEvents,
      headerToolbar: {
        start: 'prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      },
      bootstrapFontAwesome: {
        close: ' la la-times',
        prev: ' la la-angle-left',
        next: ' la la-angle-right',
        prevYear: ' la la-angle-double-left',
        nextYear: ' la la-angle-double-right',
      },
      buttonText: {
        today: 'Today',
        dayGridMonth: 'Month',
        timeGridWeek: 'Week',
        timeGridDay: 'Day',
        listMonth: 'List',
      },
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      allDaySlot: false,
      weekends: true,
    }
  }

  public query_settings: any = {
    custom_fields: [
      {
        name: 'is:top_level',
        value: 'is:top_level',
      },
      {
        name: 'is_not:top_level',
        value: 'is_not:top_level',
      },
      {
        name: 'is:archived',
        value: 'is:archived',
      },
      {
        name: 'is_not:archived',
        value: 'is_not:archived',
      },
      {
        name: 'is:active',
        value: 'is:active',
      },
      {
        name: 'is:inactive',
        value: 'is:inactive',
      },
      /* {
        name: 'has:schedulers',
        value: 'has:schedulers',
      },
      {
        name: 'has_not:schedulers',
        value: 'has_not:schedulers',
      }, */
    ],
  };

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public rows(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return Inventory.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public editInventory(inventory: Inventory) {
    this.$router.push(`/app/inventory/${inventory.id}`)
  }

  public toggleStatus(inventory: Inventory) {
    this.modal.status = true
    this.target = inventory
  }

  public confirmToggleStatus() {
    if (this.target.status) {
      this.target.deactivate().then(() => {
        this.dataTable.refresh()
      })
    } else {
      this.target.activate().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public toggleSchedulers(inventory: Inventory) {
    this.modal.schedulers = true
    this.target = inventory
  }

  public confirmToggleSchedulers() {
    if (this.target.disable_schedulers) {
      this.target.enableSchedulers().then(() => {
        this.dataTable.refresh()
      })
    } else {
      this.target.disableSchedulers().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public enableScheduler(inventory: Inventory, scheduler: AdunitScheduler) {
    this.modal.enable_scheduler = true
    this.target = inventory
    this.target_scheduler = scheduler
  }

  public confirmEnableScheduler() {
    this.target_scheduler.enableAdunit(this.target).then(() => {
      this.dataTable.refresh()
    })
  }

  public disableScheduler(inventory: Inventory, scheduler: AdunitScheduler) {
    this.modal.disable_scheduler = true
    this.target = inventory
    this.target_scheduler = scheduler
  }

  public confirmDisableScheduler() {
    this.target_scheduler.disableAdunit(this.target).then(() => {
      this.dataTable.refresh()
    })
  }

  public printGroupName(code: string | null) {
    const group = Inventory.module.type_options.find(o => o.value === code)
    return group ? group.name : code
  }

  public printLength(length: string | null) {
    return length ? `${length}s` : 'Any'
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.refresh()
  }

  public mounted() {
    this.fields = fields
    // Fectch global Scheduler
    const global_scheduler = AdunitScheduler.module.data.find(
      s => s.is_global,
    )
    if (!global_scheduler) {
      AdunitScheduler.get('global')
    }

    if (LiveEvent.module.data.length === 0) {
      LiveEvent.fetchAllValidEvents().then(r => {
        this.busy = false
      })
    } else {
      this.busy = false
    }
  }

  public openScheduler(scheduler: AdunitScheduler) {
    this.$router.push(`/app/scheduler/${scheduler.id}`)
  }

  public viewHistory(inventory: Inventory) {
    this.history_loading = true
    this.modal.history = true
    this.target = inventory
    inventory
      .getHistory({
        page_size: 25,
        page: 1,
        order_by: 'created_at',
        order: 'desc',
        query: [],
      })
      .then((response: any) => {
        this.history = response.data.result.results
        this.history_records = response.data.result.records
        this.history_loading = false
      })
  }

  public viewChildren(inventory: Inventory | null = null) {
    const q = _clone(this.query).filter(
      (q: string) => !q.includes('parent_id:') && !q.includes(':top_level'),
    )
    q.push(inventory ? `parent_id:${inventory.id}` : 'is:top_level')

    if (inventory) {
      this.searchInput.addCustomTag({
        name: `parent:${inventory.name}`,
        value: `parent_id:${inventory.id}`,
      })
    }

    this.query = q
  }
}
